import React, { useEffect, useState } from 'react'
import SectionNavbar from '../components/sheared/SectionNavbar'
import style from '../assets/styles/section.module.css'
import homeVideo from '../assets/videos/VRadicals_Video.mp4'
import FooterPage from '../Footer/FooterPage.jsx'
import toast from 'react-hot-toast'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const ContactPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email && name) {
            try {
                const response = await axios.post('https://dirtrage.games/api/v2/user/add-lead', {
                    name,
                    email,
                    phone,
                    message,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const res = response.data;
                if (res) {
                    localStorage.setItem("success", true)
                    navigate('/success')
                }
            } catch (error) {
                toast.error(error.response.data.message);
                console.error('Error submitting form:', error.response.data.message);

            }
        }
    };

    return (
        <>
            <SectionNavbar />
            <section className={style.about_main_container}>
                <div className={style.about_wrapper}>
                    <video
                        src={homeVideo}
                        autoPlay
                        muted
                        loop
                        className={`${style.home_video} object-cover`}
                    ></video>
                    <img src="/images/headingBG.webp" alt="headingImg" />
                    <div className={style.about_img_effect}></div>
                    <h1 className={style.section_heading}>Contact</h1>
                </div>
                <div className={style.contact_main_container}>
                    <div className={style.contact_inner_wrapper}>
                        <div className={style.contact_content_wrapper}>
                            <h1>ContacUs</h1>
                            <p>Our journey began with a simple idea - to make virtual reality accessible and beneficial for all sectors. Today, we're delivering innovative solutions in h</p>
                        </div>
                        <div className={style.contact_input_form}>
                            <img src="/images/contactBG_Img.webp" alt="" />
                            <form onSubmit={handleSubmit} className={style.contact_inner_input_form}>
                                <h1>Get In Touch</h1>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        id="floating_name" class="block py-2.5 px-0 w-full text-sm text-gray-100 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />
                                    <label htmlFor="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" inputmode="numeric" name="phone" title="88888-88888" pattern="[1-9]{1}[0-9]{9}"
                                        value={phone}
                                        onChange={(e) => {
                                            const inputPhone = e.target.value;
                                            // Remove any non-numeric characters
                                            const numericPhone = inputPhone.replace(/\D/g, '');
                                            // Limit the phone number to 10 digits
                                            const formattedPhone = numericPhone.slice(0, 10);
                                            setPhone(formattedPhone);
                                        }}
                                        id="floating_phone" class="block py-2.5 px-0 w-full text-sm text-gray-100 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />
                                    <label htmlFor="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="email" name="name"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-100 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                    <label htmlFor="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" name="name"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)} id="floating_message" class="block py-2.5 px-0 w-full text-sm text-gray-100 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />
                                    <label htmlFor="floating_message" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Message</label>
                                </div>

                                <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                            </form>

                        </div>
                    </div>
                </div>
                <FooterPage />
            </section>
        </>
    )
}

export default ContactPage