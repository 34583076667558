import React, { useEffect } from "react";
import style from "../assets/styles/section.module.css";
import FooterPage from "../Footer/FooterPage";
import homeVideo from "../assets/videos/VRadicals_Video.mp4";
import SectionNavbar from "../components/sheared/SectionNavbar";
import { Link } from "react-router-dom";

const LearnMoreProducts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SectionNavbar />
      <section className={style.about_main_container}>
        <div className={style.about_wrapper}>
          <video
            src={homeVideo}
            autoPlay
            muted
            loop
            className={`${style.home_video} object-cover`}
          ></video>
          <img src="/images/headingBG.webp" alt="headngImg" />
          <div className={style.about_img_effect}></div>
          <h1 className={style.section_heading}>Products</h1>
        </div>

        <div className={style.card_container}>

          <div className={`${style.card_wrapper} px-4 py-2 `}>
            <div className={style.card_inner_wrapper}>
              <div className={`${style.service_card} `}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/KalpVerse_VR_Lab.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-evenly`}>
              <div className="mb-2">
                <h1 className="text-2xl text-white font-bold">
                  KalpVerse VR Lab
                </h1>
              </div>
              <p>
                At KalpVerse VR Lab for Schools, we believe in harnessing the
                power of immersive technology to create engaging and interactive
                learning experiences across various subjects and grade levels.
                Our VR simulations transport students to historical events,
                distant planets, microscopic worlds, and beyond, allowing them
                to explore concepts in science, history, mathematics,
                literature, and more in ways never before possible. Our curated
                library of educational VR content is aligned with academic
                standards and designed to supplement traditional teaching
                methods, catering to diverse learning styles and abilities.
                Whether it's dissecting a virtual frog in biology class,
                embarking on a virtual field trip to ancient civilizations, or
                solving complex mathematical equations in a virtual laboratory,
                students are empowered to actively participate in their learning
                journey.
                <span>
                  {" "}
                  For more information Visit -{" "}
                  <Link
                    className="text-blue-400 underline"
                    to="https://www.kalpverse.io"
                  >
                    www.kalpverse.io
                  </Link>
                </span>
              </p>
            </div>
          </div>

          <div className={`${style.card_wrapper} flex-row-reverse `}>
            <div className={style.card_inner_wrapper}>
              <div className={style.service_card}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/Stock_Management_System.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-evenly`}>
              <div className="sm:mb-2">
                <h1 className="text-2xl text-white font-bold">
                  Stock Management System
                </h1>
              </div>
              <p>
                Vradicals Stock Management System offers a comprehensive and
                intuitive solution for efficient inventory management,
                empowering businesses to take control of their stock with ease.
                Our user-friendly platform is designed to simplify the
                complexities of inventory tracking, allowing you to effortlessly
                monitor stock levels, track inventory movement, and streamline
                order processing all in one place. Gone are the days of
                stockouts and overstocking, as our system provides real-time
                visibility into your inventory levels, ensuring that you always
                have the right amount of stock on hand to meet customer demand
                without excess. By accurately tracking inventory movement, you
                can identify trends, forecast demand, and make informed
                decisions to optimize your inventory control strategies. Our
                platform streamlines the entire order processing workflow, from
                receiving orders to fulfillment, reducing manual errors and
                streamlining operations.
              </p>
            </div>
          </div>

          <div className={`${style.card_wrapper} px-4 py-2 `}>
            <div className={style.card_inner_wrapper}>
              <div className={`${style.service_card} `}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/360.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-evenly`}>
              <div className="sm:mb-2">
                <h1 className="text-2xl text-white font-bold">
                  360° Walkthrough
                </h1>
              </div>
              <p>
                Immerse yourself in a world of breathtaking views and
                interactive experiences with our specialized 360° Walkthrough
                virtual tours. Whether you're showcasing real estate properties,
                event venues, or tourist attractions, our cutting-edge
                technology brings your space to life like never before. Step
                into each scene and take control of your journey, zooming in to
                examine intricate details and panning around to explore
                different angles. For real estate professionals, our tours offer
                a game-changing way to showcase properties, enabling potential
                buyers to tour homes from anywhere in the world, 24/7. Planning
                an event? Let clients and guests explore your venue virtually
                before they even arrive, helping them make confident decisions
                and reducing the need for in-person visits.
              </p>
            </div>
          </div>

          <div className={`${style.card_wrapper} flex-row-reverse `}>
            <div className={style.card_inner_wrapper}>
              <div className={style.service_card}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/School_Management_Software.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-evenly`}>
              <div className="mb-2">
                <h1 className="text-2xl text-white font-bold">
                  School Management Software
                </h1>
              </div>
              <p>
                Experience the transformative power of School Management
                Software, your trusted partner in revolutionizing educational
                administration. Our all-in-one solution simplifies the
                complexities of school management, offering seamless student
                records management, intuitive attendance tracking, and enhanced
                communication channels between educators, parents, and students.
                Say goodbye to manual paperwork and embrace effortless
                administrative tasks with our user-friendly platform.
                Personalize the learning experience for every student, track
                progress, and deliver targeted interventions to support academic
                success. With robust reporting and analytics tools, make
                data-driven decisions with confidence, driving continuous
                improvement. Rest assured, our secure and scalable platform
                ensures the safety of your data and grows alongside your
                institution's needs. Join us in transforming education with
                School Management Software, where the future of learning begins.
              </p>
            </div>
          </div>
          <div className={`${style.card_wrapper}`}>
            <div className={style.card_inner_wrapper}>
              <div className={style.service_card}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/Point_of_Sale_System.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-evenly`}>
              <div>
                <h1 className="text-2xl text-white font-bold">
                  Point of Sale System
                </h1>
              </div>
              <p>
                Welcome to the future of retail management with our
                comprehensive solution designed to revolutionize how you handle
                sales, inventory, and customer transactions. Our platform offers
                everything you need to streamline your retail operations,
                providing you with the tools to track inventory in real-time and
                deliver seamless checkout experiences for your customers. Gone
                are the days of manual processes and tedious inventory
                management. With our intuitive Point of Sale (POS) system, you
                can effortlessly process transactions, manage inventory levels,
                and keep track of sales data with ease. Our user-friendly
                interface makes it simple for your staff to navigate the system,
                reducing training time and ensuring smooth operations during
                busy periods. Real-time inventory tracking is at the core of our
                solution, allowing you to monitor stock levels, track product
                movement, and make informed purchasing decisions.
              </p>
            </div>
          </div>

        </div>
        <FooterPage />
      </section>
    </>
  );
};

export default LearnMoreProducts;
