import React, { useEffect } from 'react'
import style from '../assets/styles/section.module.css'
import FooterPage from '../Footer/FooterPage'
import homeVideo from '../assets/videos/VRadicals_Video.mp4'
import SectionNavbar from '../components/sheared/SectionNavbar.jsx'
const LearnMoreAbout = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SectionNavbar />
            <section className={style.about_main_container}>
                <div className={style.about_wrapper}>
                    <video
                        src={homeVideo}
                        autoPlay
                        muted
                        loop
                        className={`${style.home_video} object-cover`}
                    ></video>
                    <img src="/images/headingBG.webp" alt="headingImg" />
                    <div className={style.about_img_effect}></div>
                    <h1 className={style.section_heading}>About</h1>
                </div>
                <div className={style.about_content_container}>
                    <div className={style.about_content_wrapper}>

                        <div className={style.about_top_container}>
                            <img src="/images/section_about_img1.webp" alt="" />
                            <div className={style.about_top_content}>
                                <h1>Our Vision At VRadicals</h1>
                                <p className='text-justify'> At VRadicals, we are not just passionate about VR; we're committed to harnessing its transformative power to shape the future of human interaction and experience. With our multidisciplinary team, we merge expertise in software development, UX design, psychology, and more to create VR solutions that redefine immersion and engagement.
                                    In education, we're reimagining how students learn by transporting them to historical events, distant planets, or complex scientific concepts, making learning an unforgettable adventure. In healthcare, our VR applications are revolutionizing therapy and rehabilitation, providing patients with immersive environments that facilitate healing and recovery in ways traditional methods cannot match.
                                    For businesses, we offer innovative training solutions that simulate real-world scenarios, allowing employees to practice skills and decision-making in a risk-free environment. Our entertainment experiences transport users to fantastical realms, where they can explore, interact, and create memories like never before.
                                    But our mission goes beyond entertainment and education. We're pioneering the use of VR for mental health and well-being, providing therapeutic experiences that alleviate stress, anxiety, and trauma. Through guided mindfulness exercises, exposure therapy, and immersive relaxation environments, we're helping individuals find peace and resilience in an increasingly chaotic world.
                                </p>
                            </div>
                        </div>

                        <hr className={style.after_article_line} />

                        <div className={style.about_bottom_container}>
                            <img src="/images/section_about_img2.webp" alt="section_about" />
                            <div className={style.about_bottom_content}>
                                <h1>Our Mission</h1>
                                <p className='text-justify'>At the heart of our vision lies the recognition of VR's capacity to revolutionize how we interact with information, environments, and each other. We believe that by harnessing the power of VR, we can foster empathy, understanding, and creativity on a scale never before possible. Through partnerships with innovators in various industries, we are constantly pushing the boundaries of what is achievable in virtual realms.

                                    One of our key focuses is on the educational sector. We are passionate about redefining traditional learning paradigms by offering immersive educational experiences that cater to diverse learning styles and preferences. From exploring ancient civilizations in history class to conducting virtual science experiments in a laboratory setting, our educational VR content sparks curiosity and fosters a deeper understanding of complex concepts.

                                    Moreover, we understand the importance of inclusivity in the development and deployment of VR technologies. That's why we prioritize designing experiences that are accessible to individuals with disabilities, ensuring that everyone can participate fully in the immersive journey.

                                    In the realm of healthcare, we are pioneering the use of VR for therapeutic purposes, providing patients with immersive experiences that alleviate pain, reduce stress, and aid in rehabilitation. Our collaborations with medical professionals and researchers are driving innovation in this field, offering new avenues for treatment and healing.</p>
                            </div>
                        </div>

                        <div className={style.about_top_container}>
                            <img src="/images/section_about_img3.webp" alt="" />
                            <div className={style.about_top_content}>
                                <h1>Expert Team</h1>
                                <p className='text-justify'>In addition to our proficient developers and creative designers, our team also includes experts in UX research and testing. By employing rigorous user testing methodologies, we ensure that every VR experience we create not only looks visually stunning but also delivers an intuitive and seamless user interaction. This approach allows us to iterate and refine our designs based on real user feedback, ultimately enhancing the overall user satisfaction and engagement.

                                    Furthermore, our team stays at the forefront of emerging VR technologies and trends, constantly exploring new possibilities to push the boundaries of what's achievable in virtual reality. Whether it's leveraging the latest advancements in hardware, such as haptic feedback devices or eye-tracking technology, or experimenting with novel interaction paradigms, we are committed to delivering state-of-the-art VR experiences that captivate audiences and exceed expectations.

                                    Beyond technical expertise, our team places a strong emphasis on collaboration and communication. We work closely with our clients throughout every stage of the development process, from initial concept ideation to final deployment and beyond. This collaborative approach ensures that we fully understand our clients' goals and objectives, allowing us to tailor our solutions to meet their specific needs and requirements effectively.</p>
                            </div>
                        </div>
                        <div className={style.about_bottom_container}>
                            <img src="/images/section_about_img4.webp" alt="section_about" />
                            <div className={style.about_bottom_content}>
                                <h1>Tailored Experiences</h1>
                                <p className='text-justify'>
                                    At the heart of our work lies a passion for storytelling and innovation. We understand that in today's digital landscape, simply presenting information or products isn't enough; there's a need to craft compelling narratives that resonate deeply with audiences. This is where our expertise in virtual reality (VR) truly shines.

                                    With VR, we have the power to transcend the limitations of traditional mediums and transport users into fully immersive environments. Imagine taking your audience on a journey through time and space, allowing them to experience historical events firsthand or explore fantastical realms limited only by imagination.

                                    But it's not just about creating visually stunning landscapes; it's about imbuing them with meaning and purpose. We work closely with our clients to understand their objectives and weave narratives that not only entertain but also inform and inspire.

                                    Our team consists of not just developers, but visionaries who are constantly pushing the boundaries of what's possible in VR. We stay at the forefront of technological advancements, eagerly embracing new tools and techniques to enhance the user experience.

                                    Whether you're a business looking to showcase your latest product in a revolutionary way, an educator seeking to revolutionize the learning experience, or a storyteller wanting to bring your narrative to life in ways previously unimaginable, we're here to make it happen.</p>
                            </div>
                        </div>

                        <div className={`${style.stage_container} w-full`}>
                            <div className={`w-[52%] h-auto flex justify-center items-center flex-col `} style={{ margin: "0 auto" }}>
                                <div className='w-[7rem] h-[7rem] relative bg-[#41c0ff] flex justify-center items-center  rounded-full'>
                                    <h3 className='text-black font-bold'>Mission</h3>
                                </div>

                                <div className={` relative top-[-4rem] h-[25rem] z-[-1]`} style={{ borderLeft: 'dashed #9834f5' }}>

                                    <div className={`${style.card_arrow_right} border absolute w-[23rem] left-[-28rem] bg-[#e9e9e91c] text-white`}>
                                        <p className='p-4'>Our mission is to provide innovative software solutions for excellence and enterprise compliance.</p>
                                    </div>

                                    <div className='w-[7rem] h-[7rem] bg-[#b946ee] absolute top-[50%] left-[-3.7rem] flex justify-center items-center  rounded-full'>
                                        <h3 className='text-black font-bold'>Vision</h3>
                                    </div>

                                    <div className={`${style.card_arrow_left} border absolute w-[23rem] bottom-[-2rem] left-[4.5rem] bg-[#e9e9e91c] text-white`}>
                                        <p className='p-4 '>Our vision is to become businesses’ first choice when it comes to software development and maintenance. To accomplish this, we always try to exceed our client’s expectations. Techasoft strives to build lasting partnerships and ensures client satisfaction.</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={`${style.stage_responsive_container} flex flex-col gap-2`}>
                            <div className='bg-[#a955ef] p-4 rounded '>
                                <h1 className='text-center font-bold text-black'>Mission</h1>
                            </div >
                            <div className=' p-4 rounded  text-gray-500'>
                                <p>Our mission is to provide innovative software solutions for excellence and enterprise compliance.</p>
                            </div>
                            <div className='bg-[#b946ee] p-4 rounded '>
                                <h1 className='text-center font-bold text-black'>Vision</h1>
                            </div >
                            <div className=' p-4 rounded  text-gray-500'>
                                <p>Our vision is to become businesses’ first choice when it comes to software development and maintenance. To accomplish this, we always try to exceed our client’s expectations. Techasoft strives to build lasting partnerships and ensures client satisfaction.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterPage />
            </section>

        </>
    )
}

export default LearnMoreAbout