import React from 'react'
import Navbar from './components/sheared/Navbar'
import HomePage from './Home/HomePage'
import AboutPage from './About/AboutPage'
import MediaEventPage from './Media&Event/MediaEventPage'
import ContributionPage from './Contribution/ContributionPage'
import BlogPage from './Blog/BlogPage'
import TestimonialPage from './Testinominal/TestimonialPage'
import FooterPage from './Footer/FooterPage'
import ServicePage from './servicePage/ServicePage'
import PartnerCrousel from './partnerCarousel/PartnerCarousel'
// import ThoughtsPage from './Thoughts/ThoughtsPage'

const MainPages = () => {
    return (
        <>
            <Navbar />
            <HomePage />
            <AboutPage />
            <ServicePage />
            {/* <ThoughtsPage /> */}
            <MediaEventPage />
            <ContributionPage />
            <BlogPage />
            <TestimonialPage />
            <PartnerCrousel/>
            <FooterPage />
        </>
    )
}

export default MainPages