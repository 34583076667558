import React, { useEffect } from "react";
import style from "../assets/styles/section.module.css";
import FooterPage from "../Footer/FooterPage.jsx";
import homeVideo from "../assets/videos/VRadicals_Video.mp4";
import SectionNavbar from "../components/sheared/SectionNavbar.jsx";
const ServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SectionNavbar />
      <section className={style.about_main_container}>
        <div className={style.about_wrapper}>
          <video
            src={homeVideo}
            autoPlay
            muted
            loop
            className={`${style.home_video} object-cover`}
          ></video>
          <img src="/images/headingBG.webp" alt="headngImg" />
          <div className={style.about_img_effect}></div>
          <h1 className={style.section_heading}>Service</h1>
        </div>

        <div className={style.card_container}>

          <div className={`${style.card_wrapper} px-4 py-2 `}>
            <div className={style.card_inner_wrapper}>
              <div className={`${style.service_card} `}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/VR_Development.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-between`}>
              <div>
                <h1 className="text-2xl text-white font-bold">
                  VR Development
                </h1>
              </div>
              <p className="text-gray-700 mb-4">
                At Vradicals we specialize in pushing the boundaries of
                innovation through cutting-edge Virtual Reality (VR) development
                services. Step into a world where imagination meets technology,
                where the possibilities are limited only by your creativity.
                Bring your locations to life with interactive 360° tours.
                Whether it's showcasing real estate properties, hospitality
                venues, or tourist attractions, our 360° tours allow users to
                explore every corner and detail from the comfort of their own
                screens.
              </p>
              <div className="flex justify-start gap-4 flex-wrap">
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/unity.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/unreal.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/blender.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/oculus.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/after_effect.webp"
                    alt="img"
                  />
                </span>
              </div>
            </div>
          </div>

          <div className={`${style.card_wrapper} flex-row-reverse `}>
            <div className={style.card_inner_wrapper}>
              <div className={style.service_card}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/MOBILE_APPLICATION_DEVELOPMENT.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-around`}>
              <div>
                <h1 className="text-xl text-white font-bold">
                  MOBILE APPLICATION DEVELOPMENT
                </h1>
              </div>
              <p className="mb-4">
                {" "}
                We specialize in creating captivating mobile apps with engaging
                user experiences and robust backend functionality. From native
                to hybrid solutions, we turn your vision into reality with our
                streamlined process. We specialize in creating apps that
                captivate users from the first tap. Say goodbye to boring
                interfaces and hello to interactive magic! Our team crafts
                stunning designs and brings them to life with seamless coding
                and testing. We're with you every step of the way, from
                launching your app to providing ongoing support and updates.
              </p>
              <div className="flex justify-start flex-wrap gap-4">
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/Mobile_App/android.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/Mobile_App/flutter.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/Mobile_App/ios.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/Mobile_App/titanium.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain"
                    src="/images/service_icon/Mobile_App/xamarin.webp"
                    alt="img"
                  />
                </span>
              </div>
            </div>
          </div>

          <div className={`${style.card_wrapper} `}>
            <div className={style.card_inner_wrapper}>
            <div className={`${style.service_card} `}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/game_devlop.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-between`}>
              <div>
                <h1 className="text-2xl text-white font-bold">
                  Game Development
                </h1>
              </div>
              <p className="mb-4">
                Game Development is one of our most trusted services, beloved by
                our clients. Whether you're looking for 2D, 3D, or 2.5D games,
                we've got you covered. Our team of experienced developers
                specializes in creating captivating gaming experiences that keep
                players coming back for more. With years of experience in the
                industry, we have the expertise to deliver high-quality gaming
                and AR/VR solutions that exceed expectations.Whether it's 2D,
                3D, or AR/VR, our team can handle projects of any size or
                complexity, offering versatile solutions to meet your needs. Our
                dedication to client satisfaction is unmatched.
              </p>
              <div className="flex justify-start flex-wrap gap-4">
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain "
                    src="/images/service_icon/unity.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain "
                    src="/images/service_icon/unreal.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain "
                    src="/images/service_icon/google_engine.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] bg-white rounded aspect-squre object-contain "
                    src="/images/service_icon/game_maker.webp"
                    alt="img"
                  />
                </span>
              </div>
            </div>
          </div>

          <div className={`${style.card_wrapper} flex-row-reverse `}>
            <div className={style.card_inner_wrapper}>
              <div className={style.service_card}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/web_devlopment.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-between`}>
              <div>
                <h1 className="text-2xl text-white font-bold">
                  WEB APPLICATIONS
                </h1>
              </div>
              <p className="mb-4">
                Elevate your online presence with a custom web application
                designed just for you! At Vradicals we're passionate about
                crafting digital solutions that drive growth and enhance user
                experiences. Whether you're a startup looking to make a splash
                or an established business seeking to innovate, our team of
                expert developers is here to bring your vision to life. From
                sleek e-commerce platforms to robust enterprise solutions, we've
                got the expertise to tackle projects of any size or complexity.
                Start your growth with us.
              </p>
              <div className="flex justify-evenly flex-wrap gap-4">
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Web_Development/react.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Web_Development/angular.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Web_Development/java.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Web_Development/mongodb.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Web_Development/nodejs.webp"
                    alt="img"
                  />
                </span>
              </div>
            </div>
          </div>

          <div className={`${style.card_wrapper} `}>
            <div className={style.card_inner_wrapper}>
              <div className={style.service_card}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/AI_devlopment.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-around`}>
              <div>
                <h1 className="text-3xl text-white font-bold">
                  AI Development
                </h1>
              </div>
              <p className="mb-4">
                Elevate your capabilities with a custom AI solution designed
                just for you! At Vradicals, we're passionate about crafting
                AI-driven innovations that drive growth and enhance user
                experiences. Whether you're a startup looking to make a splash
                or an established business seeking to innovate, our team of
                expert developers is here to bring your vision to life. From
                intelligent automation to advanced machine learning models,
                we've got the expertise to tackle projects of any size or
                complexity. Start your growth with us.
              </p>
              <div className="flex justify-start gap-4">
                <span>
                  <img
                    loading="lazy"
                    className="w-25vw aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/AI/infinity.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-25vw aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/AI/aixego.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-25vw aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/AI/numpy.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-25vw aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/AI/matplotlib.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-25vw aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/AI/QDP.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-25vw aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/AI/sykit.webp"
                    alt="img"
                  />
                </span>
              </div>
            </div>
          </div>

          <div className={`${style.card_wrapper} flex-row-reverse `}>
            <div className={style.card_inner_wrapper}>
              <div className={style.service_card}>
                <img
                  className="rounded"
                  loading="lazy"
                  src="/images/service_icon/tech_imgs/ONLINE_MARKETING.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className={`${style.card_inner_wrapper} justify-around`}>
              <div className="">
                <h1 className="text-2xl text-white font-bold">
                  ONLINE MARKETING
                </h1>
              </div>
              <p className="mb-4">
                we specialize in helping businesses thrive in the digital world.
                With our expertise in online marketing, we empower brands to
                reach their target audience, increase visibility, and drive
                meaningful results. Our team of experienced marketers stays
                up-to-date with the latest trends and techniques to deliver
                results-driven strategies. We understand that every business is
                unique. That's why we tailor our solutions to meet your specific
                goals and objectives. We believe in open communication and
                transparency throughout the entire process, so you're always in
                the loop. Our primary focus is on delivering measurable results
                that help you achieve your business objectives.
              </p>
              <div className="flex justify-start flex-wrap gap-4">
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Digital/content_writing.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Digital/FB.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Digital/ORM.webp"
                    alt="img"
                  />
                </span>
                <span>
                  <img
                    loading="lazy"
                    className="w-[5rem] aspect-squre object-contain bg-white rounded"
                    src="/images/service_icon/Digital/SEO.webp"
                    alt="img"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <FooterPage />
      </section>
    </>
  );
};

export default ServicePage;
