import React from "react";
import style from "../assets/styles/main.module.css";
import { Link } from "react-router-dom";
const AboutPage = () => {
  return (
    <>
      <div className={style.about_container} id="about">
        <div className={style.section_heading}>
          <h1 data-aos="fade-up"
            data-aos-duration="1000"
          // data-aos-once 
          >About</h1>
        </div>
        <div className={style.about_content_wrapper}>
          <div className={style.about_img_container}>
            <div data-aos="fade-up"
              data-aos-duration="1000"
              // data-aos-once 
              className={style.about_images1}
            >
              <img src="/images/aboutImg1.webp" alt="" />
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" data-aos-once className={style.about_images2}>
              <img src="/images/aboutImg2.webp" alt="" />
            </div>
          </div>

          <div data-aos="fade-up" data-aos-duration="1000" data-aos-once className={style.about_content}>
            {/* <h2>Know -About Us</h2> */}
            <p className="mb-4 ">
              At VRadicals India Pvt Ltd, we believe that the potential of virtual reality extends far beyond entertainment and gaming. It's a tool with the power to reshape industries, enhance human experiences, and drive meaningful change in society. With this vision in mind, we are committed to pushing the boundaries of innovation and creativity to unlock the full potential of VR technology.
            </p>
            <p className="mb-4">
              Our journey began with a simple idea to make virtual reality accessible and beneficial for all sectors. Today, we're delivering innovative solutions in healthcare, education, finance services, and government agencies.
              Our diverse team of experts shares a common mission: revolutionizing how we learn, work, and engage with the world. Through cutting-edge tech, we're transforming experiences for all.
            </p>
            <p className="mb-4">
              We invite entrepreneurs, startups, artists, government entities, non-profit organizations and individuals to join us in this exciting journey towards a more immersive future.
              VRadicals has something for everyone. Let's redefine the reality together.
            </p>
            <div className={style.learn_more_btn}>
              <Link to="/about">
                <button>Learn more</button>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default AboutPage;
