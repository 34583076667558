import React, { useEffect } from 'react'
import style from '../assets/styles/section.module.css'
import FooterPage from './FooterPage.jsx'
import homeVideo from '../assets/videos/VRadicals_Video.mp4'
import SectionNavbar from '../components/sheared/SectionNavbar.jsx'
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SectionNavbar />
            <section className={style.about_main_container}>
                <div className={style.about_wrapper}>
                    <video
                        src={homeVideo}
                        autoPlay
                        muted
                        loop
                        className={`${style.home_video} object-cover`}
                    ></video>
                    <img src="/images/headingBG.webp" alt="headingImg" />
                    <div className={style.about_img_effect}></div>
                    <h1 className={style.section_heading}>Privacy Policy</h1>
                </div>
                <div className={style.privacy_container}>
                    <h1>VRadicals India PVT LTD PRIVACY POLICY</h1>

                    <p>UPDATED November, 2, 2023</p>
                    <p className='text-justify'>This privacy policy explains how information is collected and utilized by VRadicals India Ltd. via our website here at <a data-fr-linked="true" href="//www.vradicals.io">www.vradicals.io</a> and our mobile, PC and console games. If after reading this any questions or concerns about our privacy policy, please send an email to <a data-fr-linked="true" href="mailto:info@vradicals.io">info@vradicals.io</a> and we will try to address it.</p>

                    <h1>WHAT IS INFORMATION?</h1>
                    <p className='text-justify'>When VRadicals India Pvt. Ltd. The term information in this policy, we are to information specifically identifies an (such as an individual&apos;s name, address, telephone number or e-mail address), and information about individual information is directly to personal information. Personal information does include aggregate information, which is data collect the characteristics and activities of users, from any personal information has been removed. This privacy policy in no way limits or restricts our collection aggregate information or of non-personal information.</p>

                    <h1>IN GENERAL</h1>
                    <p className='text-justify'>We don't collect or record your email address or personal information, unless you send us an email ( which case your email is stored in our email servers ). Don't resell any contact have to other companies or marketers.</p>

                    <h1> Data Privacy :</h1>
                    <p className='text-justify'>
                        Any of our application does not collect any personal information from users, including names, mobile numbers, email addresses, location data, or usage data. As a result, there is no data processing involved, and no user data to delete. This Privacy Policy applies solely to our app and does not extend to external websites or applications. We may update this policy from time to time, and any changes will be posted on this page. For any questions or concerns, please contact us at info@vradicals.io
                    </p>


                    <h1>HERE ON THE WEBSITE</h1>
                    <p className='text-justify'>We do share your name or email with other companies or business partners. Your information is on the servers where we keep all our web data. automatically receive store certain other types of information whenever you with the website. For example, our servers track type of Web browser you are using, where you travel within Site, and the page to which you link from the Site. This type of collective data us to figure out how often customers use parts of the Site, so we can make the Site appealing to as customers as possible.</p>

                    <p className='text-justify'>We do not knowingly collect or solicit personal information from anyone under the age 13. If are 13, please do not attempt register or send any yourself to us. In the event that learn that we collected information a age 13 will delete that information as quickly as possible. If you believe that we might have any information from or about a child under the age of 13, contact at <a href="mailto:info@vradicals.io">info@vradicals.io</a></p>

                    <h1>CHANGES</h1>
                    <p className='text-justify'>We may Privacy Policy at time, our sole discretion. Use of information collect is subject to Privacy in effect at the time such information is used. If we make Privacy Policy, will notify you by keeping this page updated so you are always aware of what information we collect, how we use it, and under what circumstances if any, it is disclosed. we make any to this Policy, we will change the "Updated" above.</p>
                </div>

                <FooterPage />
            </section>

        </>
    )
}

export default PrivacyPolicy