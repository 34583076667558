import React from "react";
import style from "../assets/styles/main.module.css";
import { Link } from "react-router-dom";
const MediaEventPage = () => {
  return (
    <>
      <div className="h-auto">
        <div className={`${style.section_heading} `} id="media&Event">
          <h1>Media & Press Release</h1>
        </div>
        <div className={style.mediaEvent_container}>
          <div className={style.mediaEvent_wrapper}>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-once
              className={`${style.image_card} my-6 mx-6 bg-transperent rounded-lg shadow `}
            >
              <div className={style.mediaEvent_img}>
                <img className="rounded-t-lg" src="/images/img1.webp" alt="" />
              </div>
              <div
                className="mt-4"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once
              >
                {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores
            consequuntur sunt repudiandae, libero rem eos?
          </h5> */}
              </div>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-once
              className={`${style.image_card} my-6 mx-6 bg-transperent rounded-lg shadow `}
            >
              <div className={style.mediaEvent_img}>
                <img className="rounded-t-lg" src="/images/img2.webp" alt="" />
              </div>
              <div
                className="mt-4"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once
              >
                {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores
            consequuntur sunt repudiandae, libero rem eos?
          </h5> */}
              </div>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-once
              className={`${style.image_card}  my-6 mx-6 bg-transperent rounded-lg shadow `}
            >
              <div className={style.mediaEvent_img}>
                <img className="rounded-t-lg" src="/images/img3.webp" alt="" />
              </div>
              <div
                className="mt-4"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once
              >
                {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores
            consequuntur sunt repudiandae, libero rem eos?
          </h5> */}
              </div>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-once
              className={`${style.image_card} my-6 mx-6 bg-transperent rounded-lg shadow `}
            >
              <div className={style.mediaEvent_img}>
                <img className="rounded-t-lg" src="/images/img9.webp" alt="" />
              </div>
              <div
                className="mt-4"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once
              >
                {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores
            consequuntur sunt repudiandae, libero rem eos?
          </h5> */}
              </div>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-once
              className={`${style.image_card}  my-6 mx-6 bg-transperent rounded-lg shadow `}
            >
              <div className={style.mediaEvent_img}>
                <img className="rounded-t-lg" src="/images/img4.webp" alt="" />
              </div>
              <div
                className="mt-4"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once
              >
                {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores
            consequuntur sunt repudiandae, libero rem eos?
          </h5> */}
              </div>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-once
              className={`${style.image_card} my-6 mx-6 bg-transperent rounded-lg shadow`}
            >
              <div className={style.mediaEvent_img}>
                <img className="rounded-t-lg" src="/images/img5.webp" alt="" />
              </div>
              <div
                className="mt-4"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once
              >
                {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores
            consequuntur sunt repudiandae, libero rem eos?
          </h5> */}
              </div>
            </div>
          </div>
          <Link to="/media-and-events">
            <p className="text-white p-1 see-more-media text-lg">
              <b>See More</b>
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default MediaEventPage;
