import React, { useEffect } from 'react'
import style from '../assets/styles/section.module.css'
import homeVideo from '../assets/videos/VRadicals_Video.mp4'
import FooterPage from '../Footer/FooterPage'
import SectionNavbar from '../components/sheared/SectionNavbar'
const FAQ = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SectionNavbar />
            <section className={style.about_main_container}>
                <div className={style.about_wrapper}>
                    <video
                        src={homeVideo}
                        autoPlay
                        muted
                        loop
                        className={`${style.home_video} object-cover`}
                    ></video>
                    <img src="/images/headingBG.webp" alt="headingImg" />
                    <div className={style.about_img_effect}></div>
                    <h1 className={style.section_heading}>FAQ</h1>
                </div>
                <div className={style.FAQ_container}>
                    <h1>What areas does VRadicals specialize in?</h1>
                    <p>VRadicals specializes in Virtual Reality (VR), 3D Modeling, and Web3 technologies.</p>

                    <h1>What is VRadicals' mission?</h1>
                    <p>VRadicals' mission is to harness the power of cutting-edge technologies and translate them into solutions that transcend conventional boundaries.</p>

                    <h1>How has VRadicals utilized Virtual Reality (VR)?</h1>
                    <p>VRadicals has utilized Virtual Reality (VR) by creating dynamic, interactive environments that redefine user experiences in various industries.</p>

                    <h1>What is the role of 3D modeling in VRadicals' solutions?</h1>
                    <p>The role of 3D modeling in VRadicals' solutions is to breathe life into concepts and enable clients to visualize their ideas with clarity.</p>

                    <h1>What industries have benefited from VRadicals' 3D solutions?</h1>
                    <p>Industries such as architects, designers, and product developers have benefited from VRadicals' 3D solutions.</p>

                    <h1>What does VRadicals offer in terms of Web3 and Blockchain integration?</h1>
                    <p>VRadicals offers secure, efficient transactions, smart contracts, and development of decentralized applications through Web3 and Blockchain integration.</p>

                    <h1>How does VRadicals provide autonomy and security to businesses?</h1>
                    <p>VRadicals provides autonomy and security to businesses through the development of Web3 technologies and decentralized applications (DApps).</p>

                    <h1>What are some examples of VRadicals' successful projects?</h1>
                    <p>Some examples of VRadicals' successful projects include immersive property tours and lifelike medical training simulations.</p>

                    <h1>What is VRadicals' approach to innovation?</h1>
                    <p>VRadicals' approach to innovation involves continuous exploration of emerging technologies and staying at the cutting edge of the industry.</p>

                    <h1>How does VRadicals contribute to society?</h1>
                    <p>VRadicals contributes to society through various CSR initiatives that aim to make a positive impact on communities and the environment.</p>

                    <h1>What does VRadicals envision for the future?</h1>
                    <p>VRadicals envisions a future where immersive experiences powered by VR, 3D, and Web3 solutions become the norm.</p>

                    <h1>What sets VRadicals apart from other technology companies?</h1>
                    <p>What sets VRadicals apart from other technology companies is their commitment to shaping the future and redefining possibilities.</p>

                    <h1>What are some future plans of VRadicals?</h1>
                    <p>Some future plans of VRadicals include expansion and strategic partnerships to solidify their position as industry leaders.</p>

                    <h1>What is the fundamental principle that guides VRadicals' operations?</h1>
                    <p>The fundamental principle that guides VRadicals' operations is sustainability.</p>
                </div>

                <FooterPage />
            </section>

        </>
    )
}

export default FAQ