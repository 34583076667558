import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function WorkShopRegisterForm() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    course: "",
    internship: "",
    areaOfInterest: "",
    // year: "",
    // career: "",
    // workshopAttended: "",
    skillBaseLearning: "",
    feedback: "",
  });
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setFormData({ ...formData, phone: phone });
  }, [phone]);

  const courses = [
    "B.Tech in Computer Science",
    "B.Tech in Mechanical Engineering",
    "B.Tech in Civil Engineering",
    "B.Tech in Electrical Engineering",
    "B.Tech in Electronics and Communication Engineering",
    "B.Tech in Information Technology",
    "B.Tech in Chemical Engineering",
    "BCA (Bachelor of Computer Applications)",
    "MCA (Master of Computer Applications)",
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://api.technikalp.com/workShopRegistration`,
        formData
      );
      toast.success(response.data.message);

      setFormData({
        name: "",
        phone: "",
        email: "",
        course: "",
        internship: "",
        areaOfInterest: "",
        skillBaseLearning: "",
        feedback: "",
      });
    } catch (error) {
      console.log("error registering....", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-5 pb-10">
      <div className="flex gap-1 justify-center items-center mb-3">
        <img src="/images/V-Radical.webp" alt="" className="h-12" />
        <p className="font-bold text-2xl uppercase">Vradicals India</p>
      </div>

      <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6">
        Workshop Feedback Form
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name Field */}
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        {/* Phone Field */}
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Phone
          </label>
          {/* <input
            type="number"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          /> */}
          <PhoneInput
            containerStyle={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            country={"in"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            required
          />
        </div>

        {/* Email Field */}
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        {/* Course Select Field */}
        <div>
          <label
            htmlFor="course"
            className="block text-sm font-medium text-gray-700"
          >
            Course
          </label>
          {/* <select
            name="course"
            id="course"
            value={formData.course}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="" disabled>
              Select Course
            </option>
            {courses.map((course, idx) => (
              <option key={idx} value={course}>
                {course}
              </option>
            ))}
          </select> */}
          <input
            list="course"
            name="course"
            value={formData.course}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Select Course"
          />
          <datalist id="course">
            {courses.map((course, index) => (
              <option key={index} value={course} />
            ))}
          </datalist>
        </div>

        {/* year Select Field */}
        <div>
          <label
            htmlFor="currentYear"
            className="block text-sm font-medium text-gray-700"
          >
            Academic Year
          </label>
          <select
            name="currentYear"
            id="currentYear"
            value={formData.currentYear}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Select Year</option>
            <option value="1">1st</option>
            <option value="2">2nd</option>
            <option value="3">3rd</option>
            <option value="4">4th</option>
          </select>
        </div>

        {/* Internship  */}
        <fieldset className="space-y-4">
          <legend className="text-sm font-medium text-gray-700">
            Are you Looking for an Internship?
          </legend>
          <div className="flex items-center">
            <input
              id="Yes"
              name="internship"
              type="radio"
              value="Yes"
              onChange={handleChange}
              required
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label
              htmlFor="Yes"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="No"
              name="internship"
              type="radio"
              value="No"
              onChange={handleChange}
              required
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label
              htmlFor="No"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              No
            </label>
          </div>
        </fieldset>

        {/* Skill Base Learning  */}
        <fieldset className="space-y-4">
          <legend className="text-sm font-medium text-gray-700">
            Are you Interested in Skill Based Learning?
          </legend>
          <div className="flex items-center">
            <input
              id="Yes"
              name="skillBaseLearning"
              type="radio"
              value="Yes"
              onChange={handleChange}
              required
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label
              htmlFor="Yes"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="No"
              name="skillBaseLearning"
              type="radio"
              value="No"
              onChange={handleChange}
              required
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label
              htmlFor="No"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              No
            </label>
          </div>
        </fieldset>

        {/* Career Field */}
        <div>
          <label
            htmlFor="career"
            className="block text-sm font-medium text-gray-700"
          >
            Area of Interest (Skill Based Learning)
          </label>
          <select
            type="text"
            name="areaOfInterest"
            id="career"
            value={formData.areaOfInterest}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Select</option>
            <option value="AI/ML">AI/ML</option>
            <option value="Full Stack Development">
              Full Stack Development
            </option>
            <option value="AR/VR">AR/VR</option>
          </select>
        </div>

        {/* Feedback Field */}
        <div>
          <label
            htmlFor="feedback"
            className="block text-sm font-medium text-gray-700"
          >
            Feedback
          </label>
          <textarea
            name="feedback"
            id="feedback"
            value={formData.feedback}
            onChange={handleChange}
            rows={4}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full bg-blue-600 text-white font-medium py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Submit Feedback
          </button>
        </div>
      </form>
    </div>
  );
}
