import React, { useEffect, useState } from "react";
import style from "../assets/styles/main.module.css";
import { BsArrowRightCircle } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactTyped from "react-typed";
import homeVideo from '../assets/videos/VRadicals_Video.mp4'
import { Link } from "react-router-dom";

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  return (
    <>
      <div className={style.home_container} id="/">
        <video
          src={homeVideo}
          autoPlay
          muted
          loop
          className={`${style.home_video} object-cover`}

        ></video>
        {/* <div className={style.homeGradient}/> */}
        <div className={style.home_wrapper}>
          <h1>
            DRIVE INTO{" "}
            <ReactTyped
              strings={["THE FUTURE WITH VIRTUAL REALITY"]}
              typeSpeed={100}
              loop
              backSpeed={20}
              cursorChar="|"
              showCursor={true}
            />
          </h1>
          <p data-aos="fade-right"
            data-aos-duration="600"
            data-aos-once >
            Welcome to VRadicals India Pvt Ltd, where we're revolutionizing technology.
          </p>
          <p data-aos="fade-right"
            data-aos-duration="600"
            data-aos-once>
            We're more than just an IT firm - we're creators of immersive realities using VR, AR and MR.
          </p>
          <Link
            className="text-white cursor-pointer inline-block"
            to="/contact"
          >
            <button
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-once
              className={`${style.homeBtn} flex justify-center items-center gap-4 bg-transparent  text-white font-bold  border border-white rounded-full`}
            >
              Request a Demo
              <BsArrowRightCircle style={{ fontSize: '2vw' }} className={style.icon} />
            </button>
          </Link>

        </div>
        <div className={style.VRMen}>
          {
            isMobile?
            <img id="tilt-image" src="/images/VRMen mobile.webp" alt="VRMen" />
            :
            <img id="tilt-image" src="/images/VRMen.webp" alt="VRMen" />
          }
        </div>
      </div>
    </>
  );
};

export default HomePage;
