import React, { useState } from "react";
import { Link as ScrollerLink } from "react-scroll";
import { BrowserRouter, Route, Routes, } from "react-router-dom"
import style from "../../assets/styles/main.module.css";
import logo from '../../assets/images/V-Radicals.webp'
const Navbar = () => {
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      var headerElement = document.getElementById("main_header");
      headerElement.style.backgroundColor = "#090921cc";

      var tiltElement = document.getElementById("nav_logo");
      tiltElement.style.width = "3rem";
      tiltElement.style.height = "3rem";
    } else {
      var headerElement = document.getElementById("main_header");
      // headerElement.style.backgroundColor = "transparent";

      var tiltElement = document.getElementById("nav_logo");
      tiltElement.style.width = "5rem";
      tiltElement.style.height = "5rem";
    }
  }
  window.onload = function () {
    init();
  };
  function init() {
    var menu = document.getElementById("menu");
    menu.classList.add(style.transition_after_pageload);
  }

  return (
    <>

      <div class={`${style.navbar}`} id="main_header">
        <div className={style.nav_logo}>
          <ScrollerLink
            activeClass="active"
            className="text-white cursor-pointer"
            to="/"
            spy={true}
            smooth={true}
            offset={-70}
            duration={1000}
          >
            <img
              style={{ width: "5rem", height: "5rem", transition: "all 0.5s" }}
              src={logo}
              class="h-8 mr-3"
              id="nav_logo"
              alt="VRadicals Logo"
            />
          </ScrollerLink>
        </div>
        <div class={`${style.Z}`}>
          <input id="dropdown_button" type="checkbox" class={`${style.dropdown_toggle}`} />
          <label
            type="button"
            for="dropdown_button"
            class={`${style.dropdown_label} inline-flex items-center p-2 w-15 h-15 justify-center text-sm text-gray-200 rounded-lg md:hidden hover:bg-gray-800 `}
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="https://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </label>

          {/* Navigation links for big screens */}
          <div>
            <ul class={`${style.MD_navbar_items} font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-transparent md:flex-row  md:mt-0 md:border-0 md:bg-transparent`}>

              <li>
                <ScrollerLink
                  activeClass="active"
                  className="text-white cursor-pointer"
                  to="/"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >
                  Home
                </ScrollerLink>
              </li>

              <li>
                <ScrollerLink
                  activeClass="active"
                  className="text-white cursor-pointer"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >
                  About
                </ScrollerLink>
              </li>

              <li>
                <ScrollerLink
                  activeClass="active"
                  className="text-white cursor-pointer"
                  to="service"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >
                  Service
                </ScrollerLink>
              </li>

              <li>
                <ScrollerLink
                  activeClass="active"
                  className="text-white cursor-pointer"
                  to="media&Event"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >
                  Media & Events
                </ScrollerLink>
              </li>

              <li>
                <ScrollerLink
                  activeClass="active"
                  className="text-white cursor-pointer"
                  to="contribution"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >
                  Contribution
                </ScrollerLink>
              </li>

              <li>
                <ScrollerLink
                  activeClass="active"
                  className="text-white cursor-pointer"
                  to="blog"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >
                  Blog
                </ScrollerLink>
              </li>

              <li >
                <a className="text-white cursor-pointer" href="https://portfolio.vradicals.io/"> Portfolio</a>
              </li>
              <li>
                <ScrollerLink
                  activeClass="active"
                  className="text-white cursor-pointer"
                  to="testimonial"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                >
                  Testimonial
                </ScrollerLink>
              </li>
            </ul>
          </div>

          {/* Navigation links for smaller screens */}
          <div class={`${style.menu_slide_from_left}`} id="menu">
            <div class={`${style.inner_menu_slide_from_left}`}>
              <div>
                {/* Cross svg for close the navigation */}
                <label
                  style={{ top: '5px', left: '-15px' }}
                  type="button"
                  for="dropdown_button"
                  class={`${style.dropdown_label} inline-flex items-center p-2 w-15 h-15 justify-center text-sm text-gray-200 rounded-lg md:hidden hover:bg-gray-800 `}
                >
                  <span class="sr-only">Open main menu</span>
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 17 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M2 2l13 13M2 15L15 2"
                    />
                  </svg>
                </label>

                <ul class={`${style.navbar_items} font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-transparent md:flex-row  md:mt-0 md:border-0 md:bg-transparent `}>
                  <li >
                    <ScrollerLink
                      activeClass="active"
                      className={` text-white cursor-pointer`}
                      to="/"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}

                    >
                      Home
                    </ScrollerLink>
                  </li>
                  <hr />
                  <li>
                    <ScrollerLink
                      activeClass="active"
                      className="text-white cursor-pointer"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      About
                    </ScrollerLink>
                  </li>
                  <hr />

                  <li>
                    <ScrollerLink
                      activeClass="active"
                      className="text-white cursor-pointer"
                      to="service"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      Service
                    </ScrollerLink>
                  </li>
                  <hr />


                  <li>
                    <ScrollerLink
                      activeClass="active"
                      className="text-white cursor-pointer"
                      to="media&Event"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      Media & Events
                    </ScrollerLink>
                  </li>
                  <hr />
                  <li>
                    <ScrollerLink
                      activeClass="active"
                      className="text-white cursor-pointer"
                      to="contribution"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      Contribution
                    </ScrollerLink>
                  </li>
                  <hr />
                  <li>
                    <ScrollerLink
                      activeClass="active"
                      className="text-white cursor-pointer"
                      to="blog"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      Blog
                    </ScrollerLink>
                  </li>
                  <hr />
                  <li>
                    <a className="text-white cursor-pointer" href="https://portfolio.vradicals.io/"> Portfolio</a>
                  </li>
                  <hr />
                  <li>
                    <ScrollerLink
                      activeClass="active"
                      className="text-white cursor-pointer"
                      to="testimonial"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      Testimonial
                    </ScrollerLink>
                  </li>
                  <hr />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Navbar;
